@import 'styles/includes.scss';

.Button {
    $root: &;

    @extend %button;

    &--Primary {
        @extend %button-primary;
    }

    &--PrimaryInverted {
        @extend %button-primary-inverted;
    }

    &--PrimaryBorder {
        @extend %button-primary-border;
    }

    &--Secondary {
        @extend %button-secondary;
    }

    &--Tertiary {
        @extend %button-tertiary;
    }

    &--Quarternary {
        @extend %button-quarternary;
        border: none;
    }

    &--Small {
        @extend %button-small;
    }

    &--Disabled {
        @extend %button-disabled;
    }

    &--Icon {
        padding: 14px 35px;
        display: inline-flex;
        align-items: center;
        min-width: max-content;
    }

    &--CardObject {
        margin-top: 18px;
    }

    &--CookieBar {
        background: none;
        border: 0;
        width: 20px;
        height: 20px;
        padding: 10px;
        position: absolute;
        top: 15px;
        right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include media(s) {
            padding-left: 30px;
            padding-right: 30px;
            right: 0;
            margin-top: auto;
            margin-bottom: auto;
            top: 0;
            bottom: 0;
            height: 100%;
        }

        @include media(m) {
            padding-left: 60px;
            padding-right: 60px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    &--MyPageMobile {
        width: 100%;
        height: 43px;
    }

    &--NavigationMobile {
        width: 26px;
        height: 17px;
        background-image: url('#{$assetPath}svg/hamburger-menu.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: unset;
        border: none;
        padding: 0;
    }

    &--VideoModule {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
        width: 56px;
        height: 56px;
        z-index: 99;
        border: 0;

        &:hover {
            transform: scale(1.2);
        }

        &:before {
            content: '';
            display: block;
            margin: 0 auto;
            background: #fff;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid $colorBlack600;
        }
    }

    &--Contact {
        font-size: 1.8rem;
        color: $colorBlue;
        margin-top: 48px;
        text-decoration: none;
        display: block;
        text-align: center;
        padding-bottom: 12px;
        line-height: 28px;

        &:hover {
            text-decoration: underline;
        }
    }

    &--SearchModalSearch,
    &--SearchModalClose {
        background: none;
        border: 0;
        height: 78px;
        width: 78px;
        padding: 0;
        position: absolute;
        right: 6px;
        top: 0;

        @include media(m) {
            right: -30px;
        }

        img {
            margin: 0 auto;
        }
    }

    &--SearchModalSearch {
        right: 50px;

        img {
            width: 25px;
            height: 25px;
        }
    }

    &--MyPages {
        font-weight: normal;
    }

    &--SearchButton {
        background: none;
        padding: 0;
        font-weight: normal;
        padding-right: 20px;
        padding-left: 20px;
        border: none;
        aspect-ratio: 1/1;
        #{$root}__Icon {
            width: 24px;
            height: 25px;
            margin-right: 0;
        }
        border-radius: 100%;
    }

    &__Icon {
        width: 18px;
        height: 21px;
        margin-right: 8px;
    }

    &--text {
        background-color: unset;

        left: 0;
        bottom: -63px;

        &:before {
            content: '';
            display: inline-block;
            width: 7px;
            height: 12px;
            background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 16px;
            transform: rotate(180deg);
        }
    }

    &--Rotate {
        &:before {
            display: none;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 7px;
            height: 12px;
            background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: middle;
            margin-left: 16px;
        }

        @include media(s) {
            right: 128px;
        }
    }
}
