%font_cookie {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

%font_cookie_bold {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}

%font_cookie_title {
    font-family: 'Raleway', sans-serif !important;
    font-weight: 700 !important;
    font-size: 2.8rem !important;
}

#cookiescript {
    &_reportlink {
        color: $colorBlue !important;

        text-decoration: underline !important;
    }

    &_cookiescriptlink {
        color: $colorBlue !important;

        text-decoration: underline !important;
    }

    &_badge {
        display: none !important;
    }
    &_reportwrap,
    &_iabwrap  {
        height: unset !important;
        overflow: auto !important;
    }
    &_readmore {
        display: block !important;
        text-decoration: underline !important;
        &:hover {
            text-decoration: none !important;
        }
    }
    &_categories {
        div {
            padding: 0.5rem 2rem !important;
            background-color: white !important;
            color: $colorBlue !important;
            text-transform: none !important;
            border: 1px solid $colorBlue !important;
        }

        .cookiescript_active {
            background-color: $colorBlue !important;
            color: white !important;
        }
    }

    &_maintabs {
        gap: 1rem !important;
        display: flex !important;
        background: none !important;
        overflow: unset !important;
        padding-bottom: 1px;
        div {
            background-color: white !important;
            color: $colorBlue !important;
            @extend %font_cookie;
            text-transform: none !important;
            border-top-left-radius: 10px !important;
            border: 2px solid $colorBlue !important;
            // border-bottom: none !important;
            &:focus {
                outline: 2px solid $colorBlue !important;
                outline-offset: -6px;
            }
            &:nth-of-type(2) {
                border-top-left-radius: 0px !important;
                border-top-right-radius: 10px !important;
            }
        }
        .cookiescript_active {
            background-color: $colorBlue !important;
            color: white !important;
            &:focus {
                outline: 2px solid white !important;
                outline-offset: -4px;
            }
        }
    }

    &_injected {
        position: relative;
        background-color: white !important;
        color: $colorBlue !important;
        padding: 3rem !important;
        font-family: 'Open Sans', sans-serif !important;
        font-weight: 400 !important;
        font-size: 1.8rem !important;

        &.hascookiereport {
            width: 100% !important;
            max-width: 1260px !important;

            height: 100% !important;
            max-height: 100% !important;

            overflow-x: scroll;

            @include media(s) {
                max-height: 90vh !important;
            }

            @include media(m) {
                max-height: 80vh !important;
            }

            @include media(l) {
                max-height: 70vh !important;
            }
        }
        .mdc-checkbox {
            &:focus-within {
                outline: 2px solid $colorBlue !important;
                outline-offset: -8px !important;
            }

            &__native-control {
                &:focus {
                    outline: 2px solid $colorBlue !important;
                }
            }

            &__native-control:enabled ~ .mdc-checkbox__background {
                border-color: $colorBlue !important;
                border-width: 1px !important;
            }
            &__native-control:disabled ~ .mdc-checkbox__background {
                border-color: $colorBlue !important;
                border-width: 1px !important;
            }

            &__native-control:checked ~ .mdc-checkbox__background {
                border-color: $colorBlue !important;
                background-color: $colorBlue !important;

                svg {
                    color: white !important;
                }
            }
        }
        .cookiescript {
            &_fullreport {
                thead > tr > th {
                    background-color: $colorBlue !important;
                    color: white !important;
                    @extend %font_cookie;
                }

                tbody > tr:nth-of-type(even) {
                    background-color: $colorLightBlue50 !important;
                    @extend %font_cookie;
                }
            }
        }
    }

    &_category_performance {
        &:focus {
            outline: 2px solid $colorBlue !important;
        }
    }

    &_aboutwrap {
        color: $colorBlue !important;
        font-family: 'Open Sans', sans-serif !important;
        font-size: 14px !important;
    }

    &_close {
        all: unset;
        color: $colorBlue !important;
        background-color: white !important;
    }

    &_save {
        background-color: $colorBlue !important;
        color: white !important;
        border: 1px solid white !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
        text-transform: none !important;
        border-radius: 50px !important;
        font-family: 'Open Sans', sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        &:hover {
            background-color: white !important;
            color: $colorBlue !important;
            border-color: $colorBlue !important;
        }
    }

    &_accept {
        background-color: $colorBlue !important;
        color: white !important;
        border: 1px solid white !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        text-transform: none !important;
        border-radius: 50px !important;
        font-family: 'Open Sans', sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        &:hover {
            background-color: white !important;
            color: $colorBlue !important;
            border-color: $colorBlue !important;
        }
    }

    &_reject {
        background-color: white !important;
        color: $colorBlue !important;
        border: 1px solid $colorBlue !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        text-transform: none !important;
        border-radius: 50px !important;
        font-family: 'Open Sans', sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        &:hover {
            background-color: $colorBlue !important;
            color: white !important;
        }
    }

    &_manage {
        color: $colorBlue !important;

        &:hover {
            #cookiescript_manageicon {
                transform: rotate(90deg) !important;
            }
        }
        &icon {
            transition: transform 0.3s ease-in-out !important;

            .cookiescript_gear {
                fill: $colorBlue !important;
            }
        }

        &_wrap {
        }
    }

    &_checkboxs {
        color: $colorBlue !important;
    }

    &_checkbox {
        &_input {
            &:foucs {
                outline: 2px solid $colorBlue !important;
            }
        }
    }

    &_wrapper {
        color: $colorBlack;
    }

    &_buttons {
        width: 100% !important;
        margin-bottom: 2rem !important;
    }

    &_header {
        color: $colorBlue !important;
        font-family: 'Raleway', sans-serif !important;
        font-weight: 700 !important;
        font-size: 2.8rem !important;
    }

    &_description {
        @extend %font_cookie;
        color: $colorBlue !important;
    }

    &_readmore {
        color: $colorBlue !important;
        font-size: 14px !important;
    }
}

#cookiescript_header {
}

#cookiescript_description {
    span[data-cs-desc-box='true'] {
    }

    a#cookiescript_readmore {
    }
}

.cookiescript_checkbox {
    &_input {
        &:focus {
            outline: 2px solid $colorBlue !important;
        }
    }

    &_checkbox {
        color: $colorBlue !important;

        &_label {
            color: $colorBlue !important;
        }

        .mdc-checkbox {
            tab-index: 0;

            &:focus {
                outline: 1px !important;
            }

            &:checked + .mdc-checkbox__background {
                border-color: $colorBlue !important;
                background-color: $colorBlue !important;
            }

            &__background {
                border: 1px solid $colorBlue !important;
                background-color: white !important;
            }
        }

        .cookiescript_checkbox_label {
        }
    }

    &_text {
        color: $colorBlue !important;
        text-transform: none !important;
        @extend %font_cookie;
    }
}

#cookiescript_buttons {
    div {
        tabindex: 0;
        role: button;
    }
}

#cookiescript_manage_wrap {
    tabindex: 0;
    role: button;

    #cookiescript_manage {
    }
}

#cookiescript {
    &_fsd {
        &_wrapper {
            min-height: 0px !important;
        }
    }

    &_injected {
        &_fsd {
            .cookiescript_fsd {
                &_header {
                    background-color: white !important;
                    border-bottom: none !important;
                    min-height: 0px !important;
                }

                &_main {
                    &_info {
                        background-color: white !important;
                        color: $colorBlue !important;
                    }
                }

                &_title {
                    color: $colorBlue !important;
                    font-family: 'Raleway', sans-serif !important;
                    font-weight: 700 !important;
                    font-size: 2.8rem !important;
                }

                &_description {
                    color: $colorBlue !important;
                    font-family: 'Open Sans', sans-serif !important;
                }

                &_tabs {
                    background-color: white !important;

                    > .cookiescript_active {
                        background-color: white !important;
                        color: $colorBlue !important;
                        border-top: 1px solid $colorBlue !important;
                        border-top-radius: 10px !important;
                    }

                    > div {
                        background-color: $colorBlue !important;
                        color: white !important;
                        border-top: 1px solid $colorBlue !important;
                        border-top-right-radius: 10px !important;

                        &:nth-of-type(2) {
                            border-top-right-radius: 0px !important;
                            border-top-left-radius: 10px !important;
                        }
                    }

                    &_content {
                        background-color: white !important;

                        mask-height: unset !important;
                        -webkit-mask-size: unset !important;
                        border-bottom: none !important;
                    }
                }

                &_category {
                    &_info {
                        color: $colorBlue !important;
                    }

                    &_control {
                    }
                }

                &_footer {
                    background-color: white !important;
                    color: $colorBlue !important;
                    border: none !important;

                    #cookiescript_buttons {
                        margin-bottom: 0px !important;
                    }
                }

                #cookiescript_declaration {
                    background-color: white !important;
                }

                &_cookies_control {
                    color: $colorLightBlue !important;
                }
            }

            .mdc-switch.mdc-switch {
                .mdc-switch__handle::after {
                    background-color: $colorBlue100 !important;
                    border-color: $colorBlue100 !important;
                }

                &--selected:enabled {
                    .mdc-switch__handle::after {
                        background-color: $colorBlue !important;
                        border-color: $colorBlue !important;
                    }

                    .mdc-switch__track::after {
                        background-color: $colorBlue !important;
                        border-color: $colorBlue !important;
                    }
                }
            }
        }

        .cookiescript_fsd_cookies::-webkit-scrollbar,
        .cookiescript_fsd_tabs_content::-webkit-scrollbar {
            background-color: $colorBlue !important;
        }

        .cookiescript_fsd_cookies::-webkit-scrollbar-thumb,
        .cookiescript_fsd_tabs_content::-webkit-scrollbar-thumb {
            background-color: $colorBlue !important;
        }
    }
}

.cookiescript_fsd_cookies::-webkit-scrollbar-thumb,
.cookiescript_fsd_tabs_content::-webkit-scrollbar-thumb {
    background-color: $colorBlue !important;
}

.mdc-checkbox {
    &:focus {
        outline: 2px solid $colorBlue !important;
    }
}

#cookiescript_tabscontent {
    overflow-y: auto !important;
}

.cookiescript_fullreport thead > tr > th {
    @extend %font_cookie;
}
.cookiescript_fullreport tbody > tr > td {
    @extend %font_cookie;
    color: $colorBlack !important;
}

.cookiescript_category_description {
    @extend %font_cookie;
    color: $colorBlack !important;
}

#cookiescript_cookietablewrap {
    max-height: unset !important;
}

.cookiescript_vendor_name {
    @extend %font_cookie;
    color: $colorBlack !important;

    a {
        @extend %font_cookie;
    }
}
