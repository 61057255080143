@import 'styles/includes.scss';

.Footer {
    width: 100%;
    padding-top: 66px;
    position: relative;
    color: $colorBlack;
    z-index: 90;
    @include media(xl) {
        &::before {
            background: #eaefed;
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            height: 120px;
            top: 315px;
        }

        &::after {
            background: $colorBlue50;
            content: '';
            display: block;
            position: absolute;
            width: 20%;
            right: 0;
            height: 282px;
            top: 310px;
        }
    }

    &__Image {
        background-color: unset;
        display: block;
        background-image: url('#{$assetPath}svg/footer-image.svg');
        background-size: contain;
        background-position-y: bottom;
        background-repeat: no-repeat;
        width: 100%;
        height: 0;
        // (image height 527px / width 1440) * 100
        padding-top: 36.6%;
        max-width: 1440px;
        margin: 0 auto;
        position: relative;

        @include media(xl) {
            padding-top: 527px;
        }
    }

    &__Menu {
        background: $colorBlue50;
        z-index: 999;
        position: relative;
        margin-top: -2px;

        @include media(xl) {
            margin-top: -159px;
            min-height: 200px;
        }
    }

    &__MenuInner {
        @extend %container;
        font-size: 16px;
        display: flex;
        flex-flow: column;

        @include media(m) {
            max-width: 850px;
            flex-flow: row;
            justify-content: space-between;
        }

        @include media(xl) {
            padding-top: 60px;
        }
    }

    &__MenuSection {
        display: flex;
        flex-flow: column;

        a {
            display: block;
        }
    }

    &__MenuInner {
        @extend %container;
        z-index: 99;
        position: relative;
    }

    h3 {
        @extend %h3;
        padding-bottom: 16px;
    }

    h4 {
        @extend %h4;
        margin-top: 10px;
    }

    &__ContactNav {
        p {
            padding-bottom: 0;
            line-height: 23px;
        }

        a {
            display: inline-block;
        }

        a[href*="//"]:not([href*="signalisten.se"])
        {
            margin-bottom: 10px;
            padding-left: 20px;
            background-image: url('#{$assetPath}svg/icon--linkout.svg');
            background-repeat: no-repeat;
            background-position: 0 center;
            font-weight: normal;
        }

          a {
            text-decoration: none;
            color: $colorBlack;
            padding-bottom: 6px;
            line-height: 25px;

            &:hover {
                text-decoration: underline;
            }

            &[href*="//"]:not([href*="signalisten.se"]):after
            {
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                padding-left: 20px;
                background-image: url('#{$assetPath}svg/icon--linkout.svg');
                background-repeat: no-repeat;
                background-position: 0 center;
                background-size: contain;
                font-weight: normal;
                margin-left: 8px;
            }

            &[href*='signalisten.se'],
            &[href^='/'],
            &[href^='..'] {
                padding-right: 20px;

                &:after {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 9px;
                    background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
                    background-repeat: no-repeat;
                    background-position: right;
                    background-size: contain;
                    font-weight: normal;
                    vertical-align: middle;
                    margin-left: 8px;
                }
            }

            &[href^='mailto'],
            &[href^='tel'] {
            color: $colorBlue;
                &:after {
                    display: none;
                }
            }
        }

    }

    &__AddressNav {
        padding-top: 24px;

        p {
            padding-bottom: 8px;
            line-height: 23px;
        }

        a {
            display: inline-block;
            color: $colorBlue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__InfoNav {
        padding-top: 31px;

        @include media(m) {
            padding-top: 0;
        }

        a {
            text-decoration: none;
            color: $colorBlack;
            padding-bottom: 6px;
            line-height: 25px;

            &:hover {
                text-decoration: underline;
            }

            &[href*="//"]:not([href*="signalisten.se"]):after
            {
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                padding-left: 20px;
                background-image: url('#{$assetPath}svg/icon--linkout.svg');
                background-repeat: no-repeat;
                background-position: 0 center;
                background-size: contain;
                font-weight: normal;
                margin-left: 8px;
            }

            &[href*='signalisten.se'],
            &[href^='/'],
            &[href^='..'] {
                padding-right: 20px;

                &:after {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 9px;
                    background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
                    background-repeat: no-repeat;
                    background-position: right;
                    background-size: contain;
                    font-weight: normal;
                    vertical-align: middle;
                    margin-left: 8px;
                }
            }
        }
    }

    &__SocialNav {
        display: flex;
        padding-top: 57px;

        &__Link {
            width: 32px;
            height: 30px;
            background-size: contain;
            margin-right: 33px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__Link--Facebook {
            background-image: url('#{$assetPath}svg/icon--facebook.svg');
        }
        &__Link--Linkedin {
            background-image: url('#{$assetPath}svg/icon--linkedin.svg');
        }
        &__Link--Instagram {
            background-image: url('#{$assetPath}svg/icon--instagram.svg');
            margin-top: 3px;
        }
    }

    &__Copyright {
        @extend %body-copy;
        margin: 0 auto;
        text-align: center;
        padding-top: 62px;
        padding-bottom: 62px;

        @include media(m) {
            padding-top: 92px;
            padding-bottom: 92px;
        }
    }
}
