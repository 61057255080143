@import 'styles/includes.scss';

.Snackbar {
    background: $colorBlue50;
    width: 100%;
    padding: 35px;

    &__Wrap {
        @extend %container;
        // justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__Text {
        text-align: center;

        @include media(m) {
            text-align: left;
        }
    }

    &__Button {
        @extend %button;
        border: 0;
        background-color: #fff;
        border-radius: 28px;
        font-weight: 500;
        font-size: 1.6rem;
        padding: 12px 35px;
        margin-top: 15px;
        border: solid 2px $colorBlue900;

        @include media(m) {
            margin-top: 0;
            margin-left: 30px;
        }
    }
}
