@import 'styles/includes.scss';

.Cookiebar {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px;
    opacity: 1;
    background: $colorBlue;
    z-index: 99999;
    transition: all $transition;
    text-align: center;

    @include media(m) {
        flex-direction: row;
    }

    &--Hiding {
        transform: translateY(110%);
        opacity: 0;
    }

    &--Hidden {
        display: none;
    }

    &__Buttons {
        display: flex;
        flex-direction: column;

        & button {
            margin-bottom: 10px;
        }
    }
}
