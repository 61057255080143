@import 'styles/includes.scss';

.NavigationMobile {
    $root: &;

    background-color: #fff;
    width: 100%;

    &--Open {
        position: fixed;
        height: 100%;
        overflow: auto;
        height: 100vh;
        overflow: auto;
        z-index: 9999;
        top: 0;
    }

    &__HeaderInner {
        @extend %container;
        align-items: center;
        display: flex;
        padding-bottom: 26px;
        padding-top: 32px;
    }

    &__Logotype {
        display: block;
        margin: 0 auto;
        width: 91px;
        height: 33px;

        img {
            width: 91px;
            height: 33px;
        }
    }

    &__Search {
        width: 26px;
        height: 27px;
        background-image: url('#{$assetPath}svg/search--black.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: unset;
        border: none;
    }

    &__Menu {
        margin: 0 auto;
        background-color: #fff;
        display: none;
        flex-direction: column;
        left: 0;
        opacity: 0;
        position: absolute;
        transform: translateY(-10px);
        width: 100%;
        z-index: 1000;

        #{$root}--Open & {
            display: Flex;
            animation: fadeInMenu 0.25s 0.15s ease-in forwards;
            height: calc(100% - 147px);
        }
    }

    &__MyPageButton {
        a {
            border-radius: 0;
            font-weight: normal;
            justify-content: center;
        }
    }

    &__Item {
        @extend %nav-titles;
        list-style: none;
        position: relative;

        a {
            @extend %container;
            color: $colorBlack;
            text-decoration: none;
            display: block;
            height: 64px;
            display: flex;
            justify-content: center;
            flex-flow: column;
        }

        li {
            position: relative;
        }
    }

    &__SubMenu {
        @extend %nav-titles-small;
        opacity: 0;

        &--Depth-1 {
            background-color: rgba(237, 237, 237, 0.5);
            font-weight: bold;
        }

        &--Depth-2 {
            background-color: #ededed;
            padding-left: 16px;
            font-weight: normal;
        }

        &--Open {
            animation: fadeInMenu 0.25s 0.15s ease-in forwards;
        }

        a {
            @extend %container;
        }
    }

    &__ButtonToggle {
        button {
            border: none;
            width: 64px;
            height: 64px;
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 34px;
            background: unset;

            &::before,
            &::after {
                content: '';
                display: block;
                width: 12px;
                height: 2px;
                border-radius: 1px;
                background: $colorBlack;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                right: 0;
            }

            &::after {
                transition: all $transition;
                transform: rotate(90deg);
            }
        }
        &--Open {
            button {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }
    }
}

@keyframes fadeInMenu {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
