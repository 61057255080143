@import 'styles/includes.scss';

.Navigation {
    $root: &;

    background-color: #fff;

    &__Container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:after {
            content: '';
            box-shadow: 0 7px 16px 0 rgba(33, 33, 33, 0.05);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    &__MenuWrap {
        width: 100%;
        position: relative;
    }

    &__Logotype {
        display: block;
        width: 217px;
        height: 79px;
        margin: 0 auto;
        margin-top: 39px;
        margin-bottom: 13px;

        img {
            width: 217px;
            height: 79px;
        }
    }

    &__Menu {
        @extend %container;
        justify-content: space-between;
        display: flex;
    }

    &__TopMenu {
        width: 100%;
    }

    &__TopMenuInner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 28px 0 19px;

        @include media(m) {
            padding: 28px 0 36px;
        }
    }

    &__Search {
        @include media(m) {
            padding-right: 13px;
        }
        @include media(l) {
            padding-right: 30px;
        }
        @include media(xl) {
            padding-right: 53px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__MyPage {
        align-self: center;
        button {
            font-weight: normal;
        }
    }

    &__NavItem {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        @include media(m) {
            padding-right: 33px;
        }
        @include media(l) {
            padding-right: 50px;
        }
        @include media(xl) {
            padding-right: 73px;
        }

        &:last-child {
            @include media(m) {
                padding-right: 13px;
            }
            @include media(l) {
                padding-right: 30px;
            }
            @include media(xl) {
                padding-right: 53px;
            }
        }
    }

    &__NavLink {
        @extend %nav-titles;
        color: $colorBlack;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        #{$root}__NavItem--Active & {
            font-weight: 700;
            color: $colorBlue;
        }
    }

    &__DropdownMenu {
        background-color: $colorBlue50;
        position: absolute;
        left: 0;
        display: none;
        opacity: 0;
        transform: translateY(-10px);
        width: 100%;
        z-index: 1000;

        &--Visible {
            display: block;
            animation: fadeInMenu 0.25s 0.15s ease-in-out forwards;
        }
    }

    &__DropdownOuterList {
        opacity: 0;
        transform: translateY(-10px);

        #{$root}__DropdownMenu--Visible & {
            animation: fadeInMenu 0.25s 0.15s ease-in-out forwards;
        }
    }

    @keyframes fadeInMenu {
        0% {
            opacity: 0;
            transform: translateY(-5px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &__DropdownMenuInner {
        @extend %container;

        padding-top: 64px;
        padding-bottom: 64px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        #{$root}__DropdownInnerLink {
            @extend %nav-titles;
            display: block;
            color: $colorBlack;
            text-decoration: none;
            padding-bottom: 12px;
            line-height: 28px;

            &:hover {
                text-decoration: underline;
            }
        }

        & #{$root}__DropdownNavLink {
            @extend %h3;
            font-family: $fontRaleway;
            color: $colorBlack;
            text-decoration: none;
            padding-bottom: 20px;
        }
    }

    &__DropdownNavItem {
        display: flex;
        flex-flow: column;
        max-width: calc(20% - 10px);
    }
}
