@import 'styles/includes.scss';

.Wysiwyg {
    $root: &;
    @extend %container-text;

    margin-bottom: 30px;
    margin-top: 30px;

    &--NotFoundPage {
        padding: 0;
    }

    &--InfoBlock {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }

    &--VideoCard {
        margin: 0;
        overflow: auto;
        padding: 0;

        @include media(m) {
            max-height: 390px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    h1 {
        @extend %h1;
    }

    h2 {
        @extend %h2;
        margin-bottom: 10px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    h3 {
        @extend %h3;
        margin-bottom: 10px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    h4 {
        @extend %h4;
        margin-bottom: 8px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    h5 {
        @extend %h5;
        margin-bottom: 8px;
        + ul,
        + ol {
            margin-top: 8px;
            margin-bottom: 25px;
        }
    }

    a {
        @extend %links;
        color: $colorBlue900;
        text-decoration: none;

        &[href*='signalisten.se'],
        &[href^='/'],
        &[href^='..'] {
            padding-right: 5px;

            &:after {
                content: '';
                display: inline-block;
                width: 6px;
                height: 9px;
                background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
                background-repeat: no-repeat;
                background-position: right;
                background-size: contain;
                font-weight: normal;
                vertical-align: middle;
                margin-left: 8px;
            }
        }

        &[href^='mailto'] {
            &:after {
                display: none;
            }
        }
        /* &:hover { */
        /*     border-bottom: 1px solid $colorBlue; */
        /* } */
    }

    a[href$='.pdf'],
    a[href$='.docx'],
    a[href$='.ppt'],
    a[href$='.pptx'],
    a[href$='.doc'] {
        padding-left: 20px;
        background-image: url('#{$assetPath}svg/icon--download.svg');
        background-repeat: no-repeat;
        background-position: 0 center;
        font-weight: normal;
    }

    a[href*="//"]:not([href*="signalisten.se"])
    {
        padding-left: 20px;
        background-image: url('#{$assetPath}svg/icon--linkout.svg');
        background-repeat: no-repeat;
        background-position: 0 center;
        font-weight: normal;
    }

    p {
        @extend %body-copy;
        margin-bottom: 20px;
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    ol,
    ul {
        @extend %body-copy;
        margin-bottom: 20px;
        li {
            position: relative;
            margin: 8px 0;
        }
    }

    ul {
        @media print {
            list-style: initial !important;
            padding-left: 20px !important;
        }

        li {
            padding-left: 15px;

            &::before {
                content: '';
                width: 6px;
                height: 6px;
                margin: 0 10px 0 0;
                display: inline-block;
                position: absolute;
                top: 11.5px;
                left: 0;
                border-radius: 50%;
                background: $colorBlue;
            }

            @media print {
                padding-left: 0 !important;
            }
        }
    }

    ol {
        list-style-type: decimal;
        margin-left: 17px;

        li {
            padding-left: 5px;
        }
    }

    &--CookieBar {
        @extend %body-copy-small;
        @extend %container;
        color: #fff;
        padding: 0;
        padding-top: 30px;
        margin: 0;

        @include media(s) {
            padding-top: 0;
            padding-right: 60px;
            padding-left: 60px;
        }

        a {
            color: #fff;
        }
    }
}
