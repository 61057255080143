@import 'styles/includes.scss';

.NavigationLocal {
    background-color: #fff;
    border-top: $colorBlack400;
    border-top: 1px solid $colorBlack400;
    position: relative;

    &:after {
        content: '';
        box-shadow: 0 7px 16px 0 rgba(33, 33, 33, 0.05);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9;
    }

    &__Container {
        @extend %container;
    }

    &__Items {
        display: flex;

        a {
            @extend %body-copy-small;
            text-decoration: none;
            color: $colorBlack;
        }
    }

    &__Item {
        margin: 0 14px;
        z-index: 999;

        &:first-child {
            margin-left: 0;
        }

        &--Active {
            border-bottom: 3px solid $colorBlue;

            a {
                font-weight: bold;
                color: $colorBlue;
            }
        }

        &--HasChildren {
            &::after {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                background-image: url('#{$assetPath}svg/arrow--down.svg');
                background-size: cover;
                background-repeat: no-repeat;
                vertical-align: middle;
            }
        }

        a {
            display: inline-block;
            padding: 13px 0;
        }
    }

    &__SubItems {
        display: none;
        position: absolute;
        opacity: 0;
        transform: translateY(-10px);
        margin-top: 3px;

        &--Visible {
            display: block;
            animation: fadeInMenu 0.25s 0.15s ease-in-out forwards;
            z-index: 1;
        }
    }

    &__SubItem {
        width: 100%;
        a {
            width: 100%;
            background: #fff;
            min-width: 220px;
            border: 1px solid $colorBlack300;
            border-top: none;
            padding: 24px;
            font-weight: normal;
            color: $colorBlack;
            transition: all $transition;

            &:hover {
                border: 1px solid $colorBlue100;
                border-top: none;
                background: $colorBlue100;
            }
        }

        &:first-child {
            a {
                border-top: 1px solid $colorBlack300;
                transition: all $transition;
            }

            a:hover {
                border-top: 1px solid $colorBlue100;
            }
        }
    }
}

@keyframes fadeInMenu {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
